@import "@/styles/_variables.scss";












































.icon-link:focus {
  color: var(--white-color);
}
.icon-link:hover {
  color:#252525;
}
