@import "@/styles/_variables.scss";





























.footer {
  width: 100%;
  position: fixed;
  bottom: 8px;
  
  img {
    margin-left: 60px;
  }
  
  small {
    font-size: 10px;
  }
}
