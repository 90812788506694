@import "@/styles/_variables.scss";






































































#locale-changer-dropdown {
  min-width: 120px!important;
}
.flag {
  font-size: 24px;
}
.flag img {
  width: 20px;
  padding-left: 3px;
}
li:first-child {
  margin-bottom: 10px;
}
.icon-link:focus {
  color: var(--white-color);
}
.icon-link:hover {
  color:#252525;
}
